<template>
  <button
    type="button"
    class="relative w-6 h-6 cursor-pointer ml-auto mr-4 md:hidden"
    aria-label="Mobile menu button"
    :aria-expanded="active"
    @click.stop="globalNavStore.toggleMobileNav()"
  >
    <span class="sr-only">Open main menu</span>
    <span class="flex flex-col">
      <span
        class="h-0.5 bg-white absolute w-full transform transition duration-500 ease-in-out"
        :class="[active ? 'rotate-45' : '-translate-y-2']"
      ></span>

      <span
        class="h-0.5 bg-white absolute w-full transform transition duration-500 ease-in-out"
        :class="{ 'opacity-0': active }"
      ></span>

      <span
        class="h-0.5 bg-white absolute w-full transform transition duration-500 ease-in-out"
        :class="[active ? '-rotate-45' : 'translate-y-2']"
      ></span>
    </span>
  </button>
</template>

<script setup lang="ts">
import { useGlobalNavStore } from '~/stores/globalNav'

const globalNavStore = useGlobalNavStore()
const active = computed(() => globalNavStore.isMobileNavActive)
</script>
