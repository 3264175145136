<template>
  <header
    id="appHeader"
    ref="appHeaderElement"
    class="w-full bg-int-grey-01 transition-all duration-500 z-30"
    :class="[
      { 'sticky top-0 z-10': !appHeaderUtil.disableSticky.value },
      { 'large-scale': deviceScaleRatio.largeScale.value }
    ]"
  >
    <int-global-navbar />
  </header>
</template>

<script setup>
const appHeaderUtil = useAppHeader()
const deviceScaleRatio = useDeviceScaleRatio()
</script>
<style lang="postcss" scoped>
.large-scale {
  position: relative !important;
}
</style>
