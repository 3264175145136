<template>
  <footer id="int-app-footer">
    <div class="border-int-yellow-02 border-b-5" />
    <div
      class="flex items-center w-full py-4 bg-int-grey-01 transition-all duration-500"
    >
      <nav class="container" aria-label="Main Footer">
        <div
          class="flex-col sm:flex sm:flex-row justify-between items-center border-b border-int-grey-03 pb-6 my-6 transition-all duration-500"
        >
          <NuxtLink :to="{ name: 'index' }">
            <img
              src="/TAFE-logo.svg"
              class="h-8 transition-all duration-500"
              alt="The TAFE NSW logo"
            />
          </NuxtLink>

          <ul class="flex justify-between text-white mt-8 sm:mt-0">
            <li class="mr-8 flex items-center">
              <a href="tel:+61279208888" class="flex items-center">
                <Icon name="IconPhone" class="text-2xl mr-1" />
                +61 2 7920 8888
              </a>
            </li>
            <li class="flex items-center">
              <a
                href="https://tafensw.custhelp.com/app/contact-us/enquire?campaign=e2e"
                target="_blank"
                rel="noopener noreferrer"
                class="flex items-center"
              >
                <Icon name="IconChat" class="text-2xl mr-1" />
                Contact us
              </a>
            </li>
          </ul>
        </div>

        <div class="grid xl:grid-cols-4 gap-8 text-white">
          <div class="xl:col-span-3">
            <div class="md:columns-2 md:gap-8 xl:max-w-3xl">
              <h3 class="text-xl mb-4">Links</h3>
              <ul
                v-if="footerPrimaryLinks && footerPrimaryLinks.length"
                class="mb-4"
              >
                <li
                  v-for="(link, i) in footerPrimaryLinks"
                  :key="i"
                  :class="[
                    i !== footerPrimaryLinks.length - 1 ? 'mb-3' : 'mb-0'
                  ]"
                >
                  <tafe-link :item="link" />
                </li>
              </ul>
              <h3 class="text-xl mt-8 mb-4">Logins</h3>
              <ul v-if="footerSecondaryLinks && footerSecondaryLinks.length">
                <li
                  v-for="(link, i) in footerSecondaryLinks"
                  :key="i"
                  :class="[
                    i !== footerSecondaryLinks.length - 1 ? 'mb-3' : 'mb-0'
                  ]"
                >
                  <tafe-link :item="link" />
                </li>
              </ul>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-1 gap-8">
            <app-footer-newsletter />

            <div
              v-if="footerBodySubDescription"
              v-html="$md.render(footerBodySubDescription)"
            ></div>

            <div class="text-center sm:text-left xl:row-start-2">
              <h3 class="h5 mb-4">Connect with us</h3>
              <ul class="flex gap-2 justify-center sm:justify-start">
                <li
                  v-for="(social, i) in socialLinks"
                  :key="i"
                  class="list-inline-item"
                >
                  <a
                    :href="social.href"
                    target="_blank"
                    class="text-lg flex items-center justify-center bg-white text-black w-8 h-8 rounded-full hover:bg-black hover:text-white"
                  >
                    <Icon :name="social.icon" /><span class="sr-only">{{
                      social.title
                    }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="cultural-graphic border-b-16 relative bg-white text-black mt-6 mb-4"
        >
          <img
            src="/acknowledgement-top.png"
            alt=""
            class="w-full h-4 object-cover object-left relative"
          />
          <p class="border-l-8 border-brand-apricot-400 p-4 max-w-full">
            {{ footerBodyDescription }}
          </p>
        </div>
      </nav>
    </div>
    <div class="w-full py-3 bg-black text-white">
      <div class="container flex justify-between">
        <ul
          v-if="footerBottomLinks && footerBottomLinks.length"
          class="flex gap-4"
        >
          <li v-for="(item, i) in footerBottomLinks" :key="i" class="">
            <a
              v-if="item.Link"
              :href="item.Link.url"
              target="_blank"
              class="underline hover:no-underline"
              rel="noopener noreferrer"
              >{{ item.Link ? item.Link.text : '' }}</a
            >
          </li>
        </ul>
        <p>{{ footerCopyright }}</p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useIntSiteConfigStore } from '~~/stores/intSiteConfig'
const intSiteConfigStore = useIntSiteConfigStore()

interface LinkItem {
  text?: string
  url?: string
  linkType?: string
}

interface Item {
  Link?: LinkItem
}
const INTERNAL_HOSTNAME = 'https://www.tafensw.edu.au/'

const socialLinks = [
  {
    title: 'Like us on Facebook',
    href: '//www.facebook.com/tafensw',
    icon: 'IconFacebook'
  },
  {
    title: 'Find us on LinkedIn',
    href: '//www.linkedin.com/company/tafe-nsw',
    icon: 'IconLinkedin'
  },
  {
    title: 'Follow us on YouTube',
    href: '//www.youtube.com/tafe',
    icon: 'IconYoutube'
  },
  {
    title: 'Follow us on X',
    href: '//x.com/tafensw',
    icon: 'IconX'
  },
  {
    title: 'Follow us on Instagram',
    href: 'https://instagram.com/tafensw/',
    icon: 'IconInstagram'
  }
]

// Computed
const footerData = computed(() => {
  return intSiteConfigStore.config?.footer
})

const footerPrimaryLinks = computed(() => {
  return footerData?.value?.BodyLinks?.targetItems?.map((item: Item) => ({
    title: item.Link?.text,
    href: item.Link?.url,
    external: !isInternalLink(item.Link?.url || '', INTERNAL_HOSTNAME)
  }))
})

const footerSecondaryLinks = computed(() => {
  return footerData?.value?.LoginLinks?.targetItems?.map((item: Item) => ({
    title: item.Link?.text,
    href: item.Link?.url,
    external: true
  }))
})

const footerBodyDescription = computed(() => {
  return footerData?.value?.BodyDescription?.value
    .replace('\r\n', '')
    .replace(/\s+/g, ' ')
})

const footerCopyright = computed(() => {
  return footerData?.value?.FooterDescription?.value
})

const footerBottomLinks = computed(() => {
  return footerData?.value?.FooterLinks?.targetItems
})

const footerBodySubDescription = computed(() => {
  return footerData?.value?.BodySubDescription?.value
})
// Methods
function isInternalLink(url: string, baseUrl: string) {
  try {
    const base = new URL(baseUrl)
    return new URL(url, base).hostname === base.hostname
  } catch (err) {
    console.debug('errors:', err)
  }
  return false
}
</script>

<style lang="postcss" scoped>
.cultural-graphic {
  border-color: #fcaa00;
}

.cultural-graphic::after {
  @apply bg-int-grey-01 absolute block;
  content: '';
  bottom: -16px;
  right: -1px;
  left: 4rem;
  height: 9px;
}
</style>
