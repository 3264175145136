<template>
  <div
    class="back-button flex flex-row items-center h-12 w-full bg-int-grey-01 text-white cursor-pointer"
    @click.stop="globalNavStore.navigateUp()"
  >
    <icon name="IconChevronLeft" class="text-2xl mx-2" />
    <span class="flex-1">Back</span>
  </div>
</template>

<script setup lang="ts">
import { useGlobalNavStore } from '~/stores/globalNav'

const globalNavStore = useGlobalNavStore()
</script>
