<template>
  <div v-if="isVisible" class="flex bg-int-grey-02 relative z-50">
    <div
      class="w-full flex justify-between items-center max-w-screen-2xl my-0 mx-auto p-3 xl:px-12"
    >
      <p class="text-white text-base mr-3">
        {{ data.label }}
        <nuxt-link
          :to="{ name: 'index' }"
          class="text-white underline font-semibold"
        >
          {{ data.linkLabel }}
        </nuxt-link>
      </p>
      <button class="p-1.5 pb-0.5" @click="removeBar">
        <Icon
          name="IconClose"
          class="text-xl font-bold"
          :title="`Close notification message`"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const data = {
  label: 'For domestic students,',
  linkLabel: 'start your study journey here.'
}

const isVisible = ref(true)

function removeBar() {
  isVisible.value = false
}
</script>
