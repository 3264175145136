<template>
  <div :class="`nav-lv-${index}`">
    <div
      v-if="hasCloseMenuEvent"
      role="button"
      class="back-button flex flex-row items-center h-12 w-full bg-gray-800 text-white cursor-pointer"
      @click.stop="closeLoginMenu"
    >
      <icon name="IconChevronLeft" /> <span class="flex-1">Back</span>
    </div>
    <int-global-nav-back-button v-else class="md:hidden" />
    <ul v-for="(columns, i) in columnCount" :key="i">
      <li
        v-for="(item, j) in columns"
        :key="j"
        :class="{ active: j === activeChild }"
      >
        <int-global-nav-list-item :item="item" :index="j" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useAttrs } from 'vue'
const attrs = useAttrs()

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  split: {
    type: Number,
    // larger number than ever needed as default
    default: 96
  },
  activeChild: {
    type: Number,
    default: null
  }
})
const emit = defineEmits(['closeMenuEvent'])

const columnCount = computed(() => {
  return props.items.reduce((all: Array<Record<number, object>>, one, i) => {
    const ch = Math.floor(i / props.split)
    all[ch] = [].concat((all[ch] as keyof object) || [], one as keyof object)
    return all
  }, [])
})

const hasCloseMenuEvent = computed(() => {
  return attrs.closeMenuEvent
})

function closeLoginMenu() {
  emit('closeMenuEvent')
}
</script>
<style lang="postcss" scoped>
@media (min-width: 768px) {
  .mega-menu .nav-lv-2 ul {
    @apply border-r border-gray-300;
  }
  .mega-menu .nav-lv-2 ul + ul:last-of-type {
    @apply border-0;
  }
}
</style>
