<template>
  <ul class="global-nav-tools flex flex-col md:flex-row -mt-px">
    <li :class="{ hidden: !displayChat }" class="md:hidden">
      <div
        class="flex p-4 flex-row items-center cursor-pointer hover:bg-int-grey-02 focus:bg-int-grey-02"
        @click="openChat"
      >
        <span class="flex-grow">Online Chat</span>
        <icon name="IconChat" class="ml-1 font-sm text-2xl" />
      </div>
    </li>
    <li class="md:hidden">
      <a
        href="https://tafensw.custhelp.com/app/contact-us/enquire?campaign=e2e"
        target="_blank"
        rel="noopener noreferrer"
        class="flex p-4 flex-row items-center cursor-pointer hover:bg-int-grey-02 focus:bg-int-grey-02"
      >
        <span class="flex-grow">Ask a question</span>
        <icon name="IconHelp" class="ml-1 text-2xl" />
      </a>
    </li>
    <li class="md:hidden">
      <a
        href="tel:+61279208888"
        rel="noopener noreferrer"
        class="flex p-4 flex-row items-center cursor-pointer hover:bg-int-grey-02 focus:bg-int-grey-02"
      >
        <span class="flex-grow">+61 2 7920 8888</span>
        <icon name="IconPhone" class="text-2xl ml-1 text-2xl" />
      </a>
    </li>
    <li class="md:hidden">
      <a
        href='https://customer.studylink.com/index.cfm?event=security.showLogin&amp;msg=eventsecured&amp;fr=sp&amp;en=home"'
        class="flex items-center p-4 hover:bg-int-grey-02 focus:bg-int-grey-02"
      >
        <span class="relative z-10">Agent Login</span>
      </a>
    </li>
    <li class="hidden md:block">
      <a
        href="tel:+61279208888"
        class="flex items-center py-4 md:px-3 hover:bg-int-grey-02 focus:bg-int-grey-02"
      >
        <Icon name="IconPhone" class="text-xl mr-1 text-2xl" />
        <span class="relative z-10">+61 2 7920 8888</span>
      </a>
    </li>
    <li class="hidden md:block">
      <a
        href='https://customer.studylink.com/index.cfm?event=security.showLogin&amp;msg=eventsecured&amp;fr=sp&amp;en=home"'
        class="flex items-center py-4 md:px-3 hover:bg-int-grey-02 focus:bg-int-grey-02"
      >
        <span class="relative z-10">Agent Login</span>
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useIntSiteConfigStore } from '~/stores/intSiteConfig'
import { useGlobalNavStore } from '~/stores/globalNav'
import { useUiStore } from '~/stores/ui'

const intSiteConfigStore = useIntSiteConfigStore()
const globalNavStore = useGlobalNavStore()
const uiStore = useUiStore()
const { openMyChat } = useExternalWidget()

const displayChat = computed(() => {
  if (intSiteConfigStore.config?.featureFlags?.displayChatIcon === null) {
    return uiStore.isChatOpen
  }
  return intSiteConfigStore.config?.featureFlags?.displayChatIcon
})

function openChat() {
  if (displayChat.value && openMyChat) {
    openMyChat()
  }
  globalNavStore.setMobileNav(false)
}
</script>
