<template>
  <button
    v-if="item.children && item.children.length"
    class="nav-item w-full text-left flex flex-row items-center px-4 py-3 cursor-pointer md:py-2"
    :class="listItemClass"
    type="button"
    @click.stop="toggleNav(item.level, index)"
  >
    <span class="flex-1">{{ item.title }}</span>
    <icon
      name="IconChevronRight"
      class="text-base"
      :class="item.level === 0 ? 'md:hidden' : ''"
    />
  </button>

  <tafe-link
    v-else
    :item="item"
    class="flex flex-row items-center px-4 py-3 cursor-pointer md:py-2"
    :class="['nav-item ', brandColor, listItemClass]"
    no-underline
    @click="toggleNav(item.level, index), globalNavStore.closeNav()"
  />
</template>

<script setup lang="ts">
import { useGlobalNavStore } from '~/stores/globalNav'

const globalNavStore = useGlobalNavStore()

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const listItemClass = computed(() => {
  return `lv-${props.item.level} no-underline`
})

const brandColor = computed(() => {
  return (
    (props.item.color && `hover:bg-brand-${props.item.color}-300`) ||
    'hover:bg-gray-300 hover:text-black'
  )
})

function toggleNav(level: number, index: number, closeNav = false as boolean) {
  if (level === 0) {
    globalNavStore.isNavActive(level, index)
      ? globalNavStore.closeNav()
      : globalNavStore.openNav({ level, index })
  } else {
    globalNavStore.openNav({ level, index })
  }

  if (closeNav) globalNavStore.closeNav()
}
</script>

<style lang="postcss" scoped>
.nav-item.lv-0 {
  @apply py-4;
}
.nav-item.lv-0:hover,
.nav-item.lv-0.active {
  @apply bg-int-grey-02 underline;
}

@media (min-width: 768px) {
  .nav-item.lv-0 {
    @apply px-3;
  }
  .nav-item.lv-2 {
    @apply py-1;
  }
  .nav-lv-1 > ul > li > .nav-item:hover,
  .nav-lv-1 > ul > .active > .nav-item {
    @apply bg-white text-black;
  }
}
</style>
